$(function () {
  function bindHistoryClickEvent() {
    $(".history-link").on("click", function (e) {
      e.preventDefault();

      let elem = $(e.currentTarget);
      let url = elem.data("url");

      if (_.isEmpty(url) || url == null) {
        return;
      }

      $.ajax({
        url: url,
        type: "GET",
        dataType: "json",
        complete: function (res) {
          historyLoadEvent(res.responseJSON, url);
        },
        error: function (response) {
          toastr.error("Failed to retrieve history for form. Please try again later.");
        }
      });
    });
  }

  function historyLoadEvent(responseData, historyURL) {
    let uuid = responseData.uuid;
    let histories = responseData.histories;

    let iconElem = $(`#form-icon-${uuid}`);
    let tooltipElem = $("#tiptip_holder");

    // Show either the "no history" or load the histories into the table
    if (_.isEmpty(histories)) {
      $(`#form-no-history-${uuid}`).removeClass("d-none");
    } else {
      let historyElem = $(`#form-history-${uuid}`);
      let tableBodyElem = historyElem.find("tbody");

      let content = _.map(histories, function (entry) {
        return `
          <tr>
            <td>${entry.author}</td>
            <td>${entry.message}</td>
            <td>${entry.timestamp}</td>
          </tr>
        `;
      });

      tableBodyElem.empty();
      tableBodyElem.append(content.join("\n"));

      historyElem.removeClass("d-none");
    }

    // Fade out, load the new chevron, and then rebind the tooltip because it won't show otherwise
    tooltipElem.fadeOut(500);
    iconElem.html(`
      <a class="fake-link">
        <i class="fas fa-chevron-down ms-3 toggle-history" title="Hide History"></i></i>
      </a>
    `);

    tipTip("[title]");

    // Rebind the click event on the chevron so it will reset back to the default once clicked
    iconElem.find("a").off("click");
    iconElem.on("click", function () {
      tooltipElem.fadeOut(500);

      iconElem.off("click");
      iconElem.html(`
        <a class="history-link fake-link" data-url="${historyURL}">
          <i class="fas fa-chevron-right ms-3 toggle-history" title="View History"></i>
        </a>
      `);

      tipTip("[title]");

      // Hide the histories
      $(`#form-no-history-${uuid}`).addClass("d-none");
      $(`#form-history-${uuid}`).addClass("d-none");

      // Rebind the click event
      bindHistoryClickEvent();
    });
  }

  bindHistoryClickEvent();
});
