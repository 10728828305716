import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["title", "body", "footer"];

  connect() {
    this.element.addEventListener("turbo:before-fetch-request", this.showLoadingState.bind(this));
  }

  showLoadingState(event) {
    // Check if the request is a prefetch
    if (event.detail.fetchOptions.headers["X-Sec-Purpose"] == "prefetch") {
      // It's a prefetch request; do not show the loading indicator
      return;
    }

    if (this.hasTitleTarget) {
      this.titleTarget.textContent = "Loading...";
    }
    if (this.hasBodyTarget) {
      this.bodyTarget.innerHTML = `
        <div class="d-flex justify-content-center align-items-center" style="height: 200px;">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      `;
    }
    if (this.hasFooterTarget) {
      this.footerTarget.innerHTML = `
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      `;
    }
  }
}