// app/javascript/controllers/modal_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["clientFilter", "employeeFilter", "ownerType"]

  connect() {
    this.updateFilters()
  }

  updateFilters() {
    const ownerType = this.ownerTypeTarget.value

    if (ownerType === "Employee") {
      this.clientFilterTarget.disabled = true
      this.employeeFilterTarget.disabled = false
    } else if (ownerType === "Client") {
      this.clientFilterTarget.disabled = false
      this.employeeFilterTarget.disabled = true
    } else {
      this.clientFilterTarget.disabled = false
      this.employeeFilterTarget.disabled = false
    }
  }

  ownerTypeChanged() {
    this.updateFilters()
  }
}