$(function () {
  $(".send-task-reminder-email").on("click", function (e) {
    e.preventDefault();

    let elem = $(e.currentTarget);
    let userIds = elem.data("userIds");
    let userNames = elem.data("userNames");

    $('#hidden-ids-storage-field').val(userIds);

    $("#email-message-modal").on("show.bs.modal", function () {
      $("#send-to-users").html(userNames.join(", "));
    });

    $('#email-message-modal').modal('show');
  });
});
